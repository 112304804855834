import React from "react";
import { Box } from "@xstyled/styled-components";

import { Layout } from "../components/Layout";
import { Title } from "../components/Title";
import { Link } from "../components/Link";
import { openSourceProjects } from "../data/open-source-project";
import { ressources } from "../data/ressources";
import { clients } from "../data/clients";
import { Project } from "../components/Project";
import { LogoClient } from "../components/LogoClient";

export default function Home() {
  return (
    <Layout title="Hi! ">
      <Title>
        <>Hi!</>
        <>I‘m Steven :)</>
      </Title>
      <Box maxWidth={500} mt="xxl">
        <>I‘m a senior full-stack developer based in Paris.</>
      </Box>

      <Box mt="md">
        Feel free to{" "}
        <Link href="mailto:steven.sanseau@gmail.com">contact me</Link>.
      </Box>
      <Box mt="xxl">
        <Box as="h3" fontWeight="600" mb="xl" mt="0">
          Clients
        </Box>
        <Box row color="light.500">
          {clients.map(({ url, logo, name }, index) => (
            <Box
              col={{ xs: 1, md: 1 / 3, lg: 1 / 7 }}
              textAlign={{ xs: "center", md: "left" }}
              key={index}
              pb="md"
              pr="md"
            >
              <Link href={url} target="_blank">
                <LogoClient>{logo}</LogoClient>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt="xxl">
        <Box as="h3" fontWeight="600" mb="xl" mt="0">
          Open source projects
        </Box>
        <Box row>
          {openSourceProjects.map(
            ({ color, description, name, url, stars }) => (
              <Box
                col={{ xs: 1, md: 1 / 2, lg: 1 / 3 }}
                key={url}
                pb="md"
                pr="md"
              >
                <Project color={color} href={url} stars={stars} title={name}>
                  {description}
                </Project>
              </Box>
            )
          )}
        </Box>
        <Box mt="xxl">
          <Box as="h3" fontWeight="600" mb="xl" mt="0">
            Misc.
          </Box>
          <Box alignItems="stretch" row>
            {ressources.map(({ color, description, name, repository, url }) => (
              <Box col={{ xs: 1, lg: 2 / 3 }} key={repository} pb="md" pr="md">
                <Project color={color} href={url} title={name}>
                  {description}
                </Project>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
