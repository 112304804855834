export const ressources = [
  {
    repository: "Hippocrate.tech",
    name: "Hippocratic Oath on Le Monde",
    url:
      "https://www.lemonde.fr/festival/article/2018/07/05/un-serment-d-hippocrate-pour-les-professionnels-de-l-intelligence-artificielle_5326218_4415198.html",
    description: "Story about our oath on newspaper le monde.",
    color: "sub.4",
  },
  {
    repository: "Goutte d'or à Coeur",
    name: "Goutte d'or à Coeur",
    url: "https://www.gouttedoracoeur.fr/",
    description:
      '2018 documentary about the director of the social center "Accueil Goutte d\'or"',
    color: "sub.2",
  },
];
