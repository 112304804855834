import React from "react";
import {
  PayfitLogo,
  WizbiiLogo,
  WTTJLogo,
  DaphniLogo,
  ShineLogo,
  LeMondeLogo,
  AvaLogo,
} from "../components/Icons";

const height = "4em";
const width = "4em";

export const clients = [
  {
    name: "Payfit",
    url: "https://www.payfit.com",
    logo: <PayfitLogo width={width} height={height} />,
  },
  {
    name: "Welcome to the jungle",
    url: "https://www.welcometothejungle.com",
    logo: <WTTJLogo width={width} height={height} />,
  },
  {
    name: "Daphni",
    url: "https://www.daphni.com",
    logo: (
      <DaphniLogo
        alt="Daphni is an investor based in paris"
        width={width}
        height={height}
      />
    ),
  },
  {
    name: "Ava",
    url: "https://www.ava.me",
    logo: <AvaLogo width={width} height={height} />,
  },
  {
    name: "Shine",
    url: "https://www.shine.fr",
    logo: <ShineLogo width={width} height={height} />,
  },
  {
    name: "Wizbii",
    url: "https://www.wizbii.com",
    logo: <WizbiiLogo width={width} height={height} />,
  },
  {
    name: "Le Monde",
    url: "https://www.lemonde.fr",
    logo: <LeMondeLogo width={width} height={height} />,
  },
];
