export const openSourceProjects = [
  {
    url: "https://github.com/Steven-Sanseau/investigative",
    name: "Investigative Journalism",
    description:
      "I created a solution for investigative journalists who want to publish their stories: a universal app in React Native (iOS, Android and a responsive website) hosted for 5$ / month only.",
    stars: 8,
    color: "sub.1",
  },
  {
    url: "https://hippocrate.tech/",
    name: "Hippocratic Oath for data science",
    description:
      "With a team of data scientists and some tech workers, we elaborate an hippocratic oath for data scientists.",
    stars: 300,
    color: "sub.4",
  },
];
